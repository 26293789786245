<script setup>
import { ref, defineEmits, defineProps, inject, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const emit = defineEmits(["logout"]);

const profile = computed(() => store.state.profile);

const isOpen = ref(false);

function onToggle() {
  isOpen.value = !isOpen.value;
}

const handleLogout = () => {
  isOpen.value = false;
  emit("logout");
};
</script>

<template>
  <div class="left-menu-wrap" :class="{ active: isOpen }" id="left_menu_wrap">
    <div id="left_menu_btn" @click="onToggle()">
      <div><span></span><span></span><span></span></div>
    </div>
    <nav class="left-menu" id="left_menu">
      <section>
        <ul id="left_menu_content" class="menu linkhl">
          <li class="menu-head account-head">
            <span>Account</span>
          </li>
          <li class="userbar" v-if="profile">
            <span class="userbar-email"
              >{{ profile?.email }}pinia822@gmail.com</span
            >
            <a class="sign-out" @click.prevent="handleLogout">
              <i class="ico signout"></i> <span>Sign Out</span></a
            >
          </li>
          <li v-if="!profile"><a href="/sign-in">Sign in</a></li>
          <li v-if="!profile"><a href="/sign-up">Sign up</a></li>
          <li class="menu-head"><span>CurrencyExchange</span></li>
          <li><a href="/about">About</a></li>
          <li><a href="/blog">Blog</a></li>
          <li><a href="/faq">FAQ</a></li>
          <!-- <li><a href="/api">API</a></li> -->
          <li><a href="/support">Support</a></li>
          <!-- <li><a href="/affiliate">Affiliate program</a></li> -->
          <!-- <li><a href="/brand">Our brand</a></li> -->
          <li class="menu-head"><span>Rules</span></li>
          <li><a href="/terms-of-service">Terms of Service</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
        </ul>
      </section>
    </nav>
  </div>
</template>

<style scoped>
.left-menu-wrap {
  display: none;
  position: absolute;
  left: 0;
  height: 100%;
  &.active {
    #left_menu_btn {
      z-index: 10;
      div {
        span:first-child {
          transform: rotateZ(45deg) scaleX(1.5) scaleY(0.7);
        }
        span:nth-child(2) {
          transform: rotateX(90deg);
          opacity: 0;
        }
        span:last-child {
          transform: rotateZ(-45deg) scaleX(1.5) scaleY(0.7);
        }
      }
    }
  }
  #left_menu_btn {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 100%;
    padding: 1em 1.5em 1em;
    position: relative;
    z-index: 1;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    div {
      height: 17px;
      width: 17px;
      span {
        background-color: #fff;
        border-radius: 1.5px;
        display: block;
        height: 3px;
        margin-bottom: 4px;
        width: 100%;
        transition: transform 0.6s ease, opacity 0.6s ease,
          background-color 0.3s ease;
        will-change: transform, background-color;
        &:first-child {
          transform-origin: 2px 1px 0;
        }
        &:last-child {
          transform-origin: 2px 1px 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 740px) {
  .left-menu-wrap {
    display: block;
  }
}
.left-menu {
  background-color: #21284b;
  left: 0;
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  width: 75%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transform: translate(-100%, 0%);
  will-change: opacity, transform;

  section {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    max-height: 100%;
    ul {
      border-top: 1px solid #484d53;
      padding: 0;
      margin-top: 55px;
      list-style: none outside none;
      li {
        display: block;
        text-align: left;
        padding: 0;
        span,
        a {
          box-sizing: border-box;
          color: #fff;
          display: block;
          font-size: 0.9em;
          padding: 0 1em;
          line-height: 2.6em;
          text-align: left;
          text-decoration: none;
        }
        &.menu-head {
          opacity: 0.6;
          span {
            font-size: 0.9em;
          }
        }
      }
    }
  }
}

.left-menu-wrap {
  display: none;
  position: absolute;
  left: 0;
  height: 100%;
  @media (max-width: 740px) {
    display: flex;
  }
}

.left-menu-wrap {
  display: none;
  position: absolute;
  left: 0;
  height: 100%;
}

.left-menu-wrap > ul {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  float: left;
  height: 22px;
  list-style: none;
  margin-left: 15px;
  margin: 20px 24px 19px 15px;
  position: relative;
  width: 209px;
}

.active > #left_menu_btn {
}

#left_menu_btn {
  div {
    height: 17px;
    width: 17px;
    z-index: 3;
    span {
      background-color: #fff;
      border-radius: 1.5px;
      display: block;
      height: 3px;
      margin-bottom: 4px;
      width: 100%;
      transition: transform 0.6s ease, opacity 0.6s ease,
        background-color 0.3s ease;
      -webkit-transition: transform 0.6s ease, opacity 0.6s ease,
        background-color 0.3s ease;
      -moz-transition: transform 0.6s ease, opacity 0.6s ease,
        background-color 0.3s ease;
      will-change: transform, background-color;
    }
  }
  &:hover {
    div {
    }
  }
}

.active > #left_menu_btn span:first-child {
  -webkit-transform: rotateZ(45deg) scaleX(1.5) scaleY(0.7);
  -ms-transform: rotateZ(45deg) scaleX(1.5) scaleY(0.7);
  -o-transform: rotateZ(45deg) scaleX(1.5) scaleY(0.7);
  transform: rotateZ(45deg) scaleX(1.5) scaleY(0.7);
}

.active > #left_menu_btn span:nth-child(2) {
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transform: rotateX(90deg);
  opacity: 0;
}

.active > #left_menu_btn span:last-child {
  -webkit-transform: rotateZ(-45deg) scaleX(1.5) scaleY(0.7);
  -ms-transform: rotateZ(-45deg) scaleX(1.5) scaleY(0.7);
  -o-transform: rotateZ(-45deg) scaleX(1.5) scaleY(0.7);
  transform: rotateZ(-45deg) scaleX(1.5) scaleY(0.7);
}

.left-menu-wrap > ul > li {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
  will-change: transform;
}

.left-menu-wrap > ul > li:first-child {
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

#left_menu_title {
  display: table;
  height: 100%;
  width: 100%;
}

#left_menu_title > span {
  display: table-cell;
  font-size: 10px;
  line-height: 0;
  padding-top: 2px;
  vertical-align: middle;
}

#header nav.left-menu {
  background-color: #21284b;
  left: 0;
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  width: 75%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transform: translate(-100%, 0%);
  -webkit-transform: translate(-100%, 0%);
  -o-transform: translate(-100%, 0%);
  -moz-transform: translate(-100%, 0%);
  will-change: opacity, transform;
}

#header .active > nav.left-menu {
  opacity: 1;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
}

nav.left-menu > header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  height: 61px;
  padding: 26px 0 20px 60px;
}

nav.left-menu > section {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  max-height: 100%;
}

nav.left-menu ul {
  border-top: 1px solid #484d53;
  padding: 0;
  margin-top: 55px;
}

nav.left-menu li {
  display: block;
  text-align: left;
  padding: 0;
}

nav.left-menu li > a,
nav.left-menu li > span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: block;
  font-size: 1.3em;
  padding: 0 1em;
  line-height: 2.6em;
  text-align: left;
  text-decoration: none;
}

nav.left-menu li > a > i.ico {
  display: inline-block;
  margin-right: 0.5em;
  opacity: 0.7;
}

nav.left-menu li > a > i.ico:after {
  font-size: 1.2em;
}

nav.left-menu li > a:hover {
  color: #008fdf;
}

nav.left-menu li.menu-head {
  padding: 1.5em 1em 0;
  opacity: 0.6;
  font-size: 1.2em;
}

nav.left-menu li.menu-head > span {
  font-size: 0.9rem;
  padding: 0;
}

nav.left-menu li > a.signout {
  color: #ffb0b0;
}
.userbar-email {
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
}

.btn {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #008fdf;
  border-radius: 0.25em;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-flex;

  position: relative;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.sign-out {
  display: flex !important;
  align-items: center;
  font-weight: 600;
  line-height: 1em;

  i {
    margin-right: 0.5em;
    color: #ffb0b0;
    font-size: 1.3em !important;
  }

  span {
    font-size: 1.3em !important;
    color: #ffb0b0 !important;
    padding: 0 !important;
  }
}

.btn,
.userbar-email {
  padding: 1em 1.5em;
}

.userbar-email {
  font-size: 1em !important;
}

.account-head {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  opacity: 1 !important;
  padding-top: 1em !important;
  padding-right: 1em !important;

  span {
    opacity: 0.6;
  }
}
</style>
