import "./assets/main.scss";

import * as Vue from "vue";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import store from "./store";
import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import { TooltipComponent, TooltipDirective } from "vue3-tooltip";
import "vue3-tooltip/tooltip.css";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import VueCookies from "vue-cookies";
import routes from "./routes.js";
import VueAwesomePaginate from "vue-awesome-paginate";
import VueLazyload from "vue-lazyload";
import "vue-awesome-paginate/dist/style.css";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = Vue.createApp(App);

Sentry.init({
  app,
  dsn: "https://9b3a669af4a485b9c3e193841743ace9@o4507939455696896.ingest.de.sentry.io/4507939457794128",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://exchange-coin.com"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
});

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
// axios.defaults.withCredentials = true;
app.use(router).use(VueAxios, axios);
app.directive("tooltip", TooltipDirective);
app.component("tooltip", TooltipComponent);
app.use(store);
app.use(ToastPlugin);
app.use(VueCookies, { expires: "7d", sameSite: "Strict" });
app.use(VueAwesomePaginate);
app.use(VueLazyload);
app.provide("axios", app.config.globalProperties.axios).mount("#app");
