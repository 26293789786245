<template>
  <div class="exchange-estimate">
    <p>
      <template v-if="isReceivingEstimateShown">
        You get
        <b>{{ formattedEstimatedAmount }} {{ tickerUpperCase }}</b
        >.
      </template>
      <template v-if="exchangeEstimate.transactionSpeedForecast">
        Expected processing time
        <b>{{ exchangeEstimate.transactionSpeedForecast }}</b> min
      </template>
    </p>
    <p class="usdt-est">
      You send
      <b>{{ formattedAmountUsdIn }} USDT</b>
      - you get
      <b>{{ formattedAmountUsdOut }} USDT</b>
    </p>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import numeral from "numeral";

const props = defineProps({
  exchangeEstimate: {
    type: Object,
    required: true,
  },
  receiveCoin: {
    type: Object,
    required: true,
  },
});

const isReceivingEstimateShown = computed(() => {
  return (
    parseFloat(props.exchangeEstimate.estimatedAmount) > 0 && props.receiveCoin
  );
});
const tickerUpperCase = computed(() => props.receiveCoin.ticker.toUpperCase());
const formattedAmountUsdIn = computed(() => {
  return formatAmountUsd(props.exchangeEstimate.amount_usd_in);
});
const formattedAmountUsdOut = computed(() => {
  return formatAmountUsd(props.exchangeEstimate.amount_usd_out);
});
const formattedEstimatedAmount = computed(() =>
  formatAmountUsd(parseFloat(props.exchangeEstimate.estimatedAmount))
);

const formatAmountUsd = (amountUsd) => numeral(amountUsd).format("0.[0000]");
</script>
<style scoped lang="scss">
.exchange-estimate {
  text-align: center;
  .usdt-est {
    margin-top: 10px;
  }
}
</style>
