<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["closed"]);

let isActive = ref(false);

const onClose = () => {
  isActive.value = false;
  emit("closed");
};

watch(
  () => props.active,
  (actual, prev) => {
    if (isActive.value !== actual) {
      isActive.value = actual;
    }
  }
);
</script>

<template>
  <div class="popup" :class="{ active: isActive }">
    <div class="popup-bg" @click="onClose"></div>
    <div class="popup-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.popup {
  box-sizing: border-box;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -100;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0s ease 0.3s;
  -o-transition: opacity 0.2s ease-in-out, visibility 0s ease 0.3s;
  transition: opacity 0.2s ease-in-out, visibility 0s ease 0.3s;
  &.active {
    z-index: 101;
    .popup-bg {
      opacity: 1;
      visibility: visible;
    }
    .popup-wrap {
      opacity: 1;
      visibility: visible;
      z-index: 777;
    }
  }
  .popup-bg {
    height: 100%;
    left: 0;
    position: absolute;
    opacity: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    backdrop-filter: blur(10px);
    background: rgba(33, 40, 75, 0.4);
    transition: opacity 0.2s ease;
  }
  .popup-wrap {
    display: flex;
    height: 100%;
    overflow: auto;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease;
  }
}
</style>
