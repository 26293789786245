<script setup>
import TrustedItem from "@/components/main/TrustedItem.vue";
const blocks = [
  {
    title: "Save time",
    text: "Maximum exchange speed due to the full automation",
    image: "url('../../assets/images/main/trusted-block1.svg')",
  },
  {
    title: "Make an exchange",
    text: "Pick the right strategy and make favourable trades",
    image: "url('../../assets/images/main/trusted-block2.svg')",
  },
  {
    title: "Save money",
    text: "Best exchange rates and minimum commissions",
    image: "url('../../assets/images/main/trusted-block3.svg')",
  },
];
</script>

<template>
  <section class="advantages-section">
    <div class="wrapper">
      <h2><span>Trusted since 2018</span></h2>
      <div class="advantages-inner">
        <TrustedItem
          v-for="{ title, text, image } in blocks"
          :key="title"
          :title="title"
          :text="text"
          :image="image"
        ></TrustedItem>
      </div>
    </div>
  </section>
</template>

<style scoped>
.advantages-section {
  margin-bottom: 12em;
  position: relative;
  .wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 2em;
    width: 100%;
    h2 {
      font-size: 3.5em;
      color: transparent;
      background-clip: text;
      background-image: linear-gradient(180deg, #fff 0, #b1daff 100%);
      font-weight: 700;
      line-height: 1.15em;
      margin-bottom: 0.7em;
      @media only screen and (max-width: 450px) {
        font-size: 2.5em;
        text-align: center;
      }
      span {
        display: inline-block;
        width: 11em;
      }
    }
    .advantages-inner {
      display: flex;
      gap: 1.6em;
      @media only screen and (max-width: 700px) {
        display: block;
      }
    }
  }
}
</style>
