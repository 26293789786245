<script setup>
  import {ref} from "vue";

  let active = ref([false, false, false])
  function toggleItem(index) {
    active.value = active.value.map((v, i) => i!==index ? false: !v)
  }
</script>

<template>
<section class="faq-section">
  <div class="wrapper">
    <h2>
      <span>FAQ</span>
    </h2>
    <ol class="faq-list">
      <li  :class="{active: active[0]}" @click="toggleItem(0) ">
          <h4>
            <span>How can I track my order?</span>
          </h4>
        <div>
          <div class="faq-inner">
            <p>In three ways: by email (if you leave your email address), on our website (if your
              browser accepts cookies) or by viewing the transactions in the blockchain by the
              links from your order.</p>
          </div>
        </div>
      </li>
      <li  :class="{active: active[1]}" @click="toggleItem(1)">
        <h4>
          <span>Why can I trust you?</span>
        </h4>
        <div>
          <div class="faq-inner">
            <p>No registration and no need to share your personal details. We don't hold your funds,
              all exchanges take place instantly in fully automatic mode.</p>
          </div>
        </div>
      </li>
      <li  :class="{active: active[2]}" @click="toggleItem(2)">
        <h4>
          <span>Do you have hidden fees?</span>
        </h4>
        <div>
          <div class="faq-inner">
            <p>Honesty is our main priority, so we commit to full transparency and make all the fees
              clear:</p>
            <p>
              •  1% if you opt for a <b>fixed rate</b>
              <br/>
              •  0.5% if you opt for a <b>floating rate</b>
            </p>
          </div>
        </div>
      </li>
    </ol>
    <div class="faq-more">
      <a href="/faq"><span>Go to page FAQ</span> <i class="ico arrow-forward"></i></a>
    </div>
  </div>
</section>
</template>

<style scoped>
.faq-section {
  margin-bottom: 14em;
  .wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 2em;
    width: 100%;
    h2 {
      font-size: 3.5em;
      color: transparent;
      background-clip: text;
      background-image: linear-gradient(180deg,#FFF 0,#B1DAFF 100%);
      font-weight: 700;
      line-height: 1.15em;
      margin-bottom: .7em;
      position: relative;
      span {
        font-family: 'Montserrat',Arial;
        margin: 0;
        padding: 0;
        outline: 0;
      }
    }
    .faq-list {
      list-style-type: none;
      counter-reset: section;
      li {
        position: relative;
        border-bottom: 1px solid #7E7E7E;
        cursor: pointer;
        h4 {
          font-weight: 400;
          font-size: 1.8em;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 1.4rem;
          padding-top: 1.4rem;
          &:before {
            content: counter(section);
            counter-increment: section;
            font-size: 3.8em;
            font-size: 2.111111em;
            left: 0;
            width: 1.4em;
            min-width: 1.4em;
            display: block;
            text-align: center;
            margin-right: .7em;
          }
          span {
            flex: 1;
          }
          &:after {
            background-color: rgba(0,143,223,0.20);
            border-radius: 2em;
            border: 1px solid transparent;
            color: #008FDF;
            content: "\e203";
            font-family: 'Icons';
            font-size: 1.5em;
            font-size: .83333333em;
            padding: .55em;
            right: 0;
            top: 1.6em;
            transition: transform .3s ease;
            will-change: transform;
          }
        }
        &:not(.active) {
          div {
            height: 0!important;
          }
        }
        &.active {
          h4:after {
            background-color: #008FDF;
            color: #0C0D16;
            transform: rotate(45deg);
            //border-color: initial;
          }
        }
        div {
          line-height: 2em;
          margin-left: 8em;
          overflow: hidden;
          transition: height .2s linear;
          will-change: height;
          height: 130px;
          box-sizing: border-box;
          cursor: default;
          @media only screen and (max-width: 550px) {
            height: 170px;
          }
          .faq-inner {
            margin: 0;
            p {
              br {
                 margin: 0;
              }
            }
          }
        }
      }
    }
    .faq-more {
      text-align: right;
      padding: 2.34em 0;
      position: relative;
      a {
        color: #008FDF;
        display: inline-block;
        i {
          margin-left: 1em;
          display: inline-block;
          font-family: "Icons";
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          text-align: center;
          vertical-align: middle;
          &:after {
            background-color: rgba(0,143,223,0.20);
            border-radius: 2em;
            border: 1px solid transparent;
            color: #008FDF;
            font-family: 'Icons';
            font-size: 1.5em;
            padding: .55em;
            transition: transform .3s ease;
            display: inline-block;
            content: "\e201";
          }
        }
      }
    }
  }
}
</style>