import { inject } from "vue";
export class Auth {
  constructor(axios, cookies) {
    this.axios = axios;
    this.cookies = cookies;
  }

  isAuthenticated() {
    const token = this.cookies.get("auth_token");
    return token !== null;
  }

  login(email, password) {
    return this.axios
      .post("auth/login", { email, password })
      .then(({ data }) => {
        this.cookies.set("auth_token", data.token);
        return data;
      });
  }

  signup(profile) {
    return this.axios.post("auth/signup", profile).then(({ data }) => {
      this.cookies.set("auth_token", data.token);
      return data;
    });
  }

  logout() {
    return this.axios.post("auth/logout").then(({ data }) => {
      this.cookies.remove("auth_token");
      return data;
    });
  }

  profile() {
    return this.axios.get("account/profile");
  }
}
