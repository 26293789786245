import MainPage from "@/components/main/MainPage.vue";

export default [
  {
    path: "/",
    component: MainPage,
    name: "main",
  },
  {
    path: "/exchange/:id",
    component: () => import("@/components/order/OrderPage.vue"),
    name: "order",
  },
  {
    path: "/about",
    component: () => import("@/pages/about/AboutPage.vue"),
    name: "about",
  },
  {
    path: "/blog",
    component: () => import("@/pages/blog/BlogPage.vue"),
    name: "blog",
  },
  {
    path: "/blog/:id",
    component: () => import("@/pages/post/BlogPost.vue"),
    name: "post",
  },
  {
    path: "/faq",
    component: () => import("@/pages/faq/FaqPage.vue"),
    name: "faq",
  },
  {
    path: "/support",
    component: () => import("@/pages/support/SupportPage.vue"),
    name: "support",
  },
  {
    path: "/sign-in",
    component: () => import("@/pages/sign-in/SignInPage.vue"),
    name: "sign-in",
  },
  {
    path: "/sign-up",
    component: () => import("@/pages/sign-up/SignUpPage.vue"),
    name: "sign-up",
  },
  {
    path: "/terms-of-service",
    component: () => import("@/pages/terms-of-service/TermsOfServicePage.vue"),
    name: "terms-of-service",
  },
  {
    path: "/privacy-policy",
    component: () => import("@/pages/privacy-policy/PrivacyPolicyPage.vue"),
    name: "privacy-policy",
  },
];
