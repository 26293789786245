<template>
  <div class="exchange-address-wrap">
    <div class="wrap-header">Destination</div>
    <div class="field">
      <textarea
        class="nonextra"
        :class="{ error: addressError }"
        required
        autocomplete="off"
        placeholder="Your address"
        :value="value"
        @input="emitValue($event.target.value)"
      ></textarea>
      <div class="extra">
        <button
          type="button"
          class="ico paste hoverhl"
          title="Paste"
          @click="onPasteDestAddress"
        ></button>
        <button
          type="button"
          v-if="false"
          class="ico scanqr hoverhl"
          title="Scan QR code"
        ></button>
        <button
          type="button"
          :style="{ visibility: value ? 'visible' : 'hidden' }"
          class="ico close hoverhl"
          @click="onClearDestAddress"
        ></button>
      </div>
    </div>
    <span v-if="addressError" class="error-hint">{{ addressError }}</span>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  value: { type: String, required: true },
  addressError: { type: String },
});

const emit = defineEmits(["input:destAddress"]);

const emitValue = (newValue) => {
  emit("input:destAddress", newValue);
};

const onClearDestAddress = () => {
  emitValue("");
};

const onPasteDestAddress = () => {
  navigator.clipboard.readText().then((text) => emitValue(text));
};
</script>
<style scoped lang="scss">
.exchange-address-wrap {
  width: 100%;
  display: block;
  position: relative;
  margin: 2.2em auto 0;

  .wrap-header {
    padding: 0 1.2em 0.4em;
    color: #ccc;
    font-size: 0.9em;
    text-align: left;
    transition: color 0.3s ease;
    will-change: color;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
  }

  .field {
    position: relative;
    border-radius: 0.5rem;
    margin: 0.2em 0 0;

    textarea.nonextra {
      text-overflow: ellipsis;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0.5rem;
      border: 1px solid transparent;
      color: inherit;
      font-size: 1.1em;
      line-height: 1;
      padding: 1.3em 2.4em 1.05em 1em;
      width: 100%;
      resize: none;
      display: block;
      overflow: hidden;
      height: 58px;

      @media only screen and (max-width: 600px) {
        height: 44px;
        font-size: 1em;
        padding: 1.3em 4.4em 1.05em 1em;
      }

      &.error {
        border: 1px solid red;
      }
    }

    .extra {
      bottom: 0.3em;
      display: flex;
      justify-content: center;
      min-width: 2.4em;
      width: auto;
      position: absolute;
      right: 0.3em;
      top: 0.3em;
      margin: 0;

      button {
        background-color: transparent;
        border: 0;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 1.05em;
        justify-content: center;
        align-items: center;
        padding: 0.3em 0.5em;
        width: 100%;
        box-sizing: border-box;

        &.ico {
          font-family: "Icons";
          font-style: normal;
          font-weight: normal;
          line-height: 1;

          text-align: center;
          vertical-align: middle;
        }

        &.paste:after {
          content: "\e206";
          font-size: 1.4em;
        }

        &.scanqr:after {
          content: "\e207";
          font-size: 1.4em;
        }

        &.close:after {
          content: "\e10D";
          font-size: 0.7em;
        }
      }
    }
  }

  .error-hint {
    font-size: 12px;
    color: red;
    display: block;
    margin-top: 5px;
  }
}
</style>
