<template>
  <div class="container">
    <slot></slot>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 2em;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 1em;
  }
}
</style>
