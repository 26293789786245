<script setup>
import { register } from "swiper/element/bundle";
import { onMounted, ref } from "vue";
import NewsItem from "@/components/main/NewsItem.vue";
register();

const progSelectedWidth = 65;
let buttonNextDisabled = ref(false);
let buttonPrevDisabled = ref(true);
let progressPos = ref(0);

const swiperEl = ref(null);
const buttonNext = ref(null);
const buttonPrev = ref(null);
const progLine = ref(null);

const props = defineProps({
  allButtonText: String,
  title: String,
  news: Array,
});

onMounted(async () => {
  const progLineWidth = progLine.value.getBoundingClientRect().width;
  const swiperParams = {
    slidesPerView: "auto",
    spaceBetween: 10,
    autoHeight: true,
    centeredSlides: false,
    resizeObserver: true,
  };

  swiperEl.value.addEventListener("swiperprogress", (event) => {
    const [swiper, progress] = event.detail;
    progressPos.value = (progLineWidth - progSelectedWidth) * progress;

    buttonNextDisabled.value = swiper.isEnd;
    buttonPrevDisabled.value = swiper.isBeginning;
  });

  Object.assign(swiperEl.value, swiperParams);
  swiperEl.value.initialize();
  buttonNext.value.addEventListener("click", () => {
    if (buttonNext.value.classList.contains("disabled")) {
      return;
    }
    swiperEl.value.swiper.slideNext();
  });

  buttonPrev.value.addEventListener("click", () => {
    if (buttonPrev.value.classList.contains("disabled")) {
      return;
    }
    swiperEl.value.swiper.slidePrev();
  });
});
</script>

<template>
  <section class="news-section">
    <div class="wrapper">
      <h2>{{ title }}</h2>
      <div class="index-news">
        <div class="carousel-viewport">
          <swiper-container init="false" ref="swiperEl">
            <swiper-slide v-for="n in news">
              <NewsItem
                :backgroundImage="n.image"
                :title="n.title"
                :description="n.description"
                :link="`/blog/${n.id}`"
                :item="n"
              ></NewsItem>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
      <div class="nav-block">
        <div class="nav">
          <button
            type="button"
            class="btn border white circle ico arrow nav-prev"
            :class="{ disabled: buttonPrevDisabled }"
            ref="buttonPrev"
          ></button>
        </div>
        <div class="nav">
          <button
            type="button"
            class="btn border white circle ico arrow nav-next"
            :class="{ disabled: buttonNextDisabled }"
            ref="buttonNext"
          ></button>
        </div>
        <div class="nav-line-wrap">
          <div class="nav-line" ref="progLine">
            <div
              class="carousel-line-selected"
              :style="{
                width: `${progSelectedWidth}px`,
                transform: `translateX(${progressPos}px)`,
              }"
            ></div>
          </div>
        </div>
        <div class="nav-all">
          <a class="btn border" href="/blog?tab=news">All news</a>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.news-section {
  overflow: hidden;
  margin-bottom: 14em;
  .wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 2em;
    width: 100%;
    h2 {
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      background-image: linear-gradient(180deg, #fff 0, #b1daff 100%);
      font-weight: 700;
      line-height: 1.15em;
      margin-bottom: 0.7em;
      position: relative;
    }
    .index-news {
      position: relative;
      padding-top: 3em;
      margin-bottom: 3em;
      .carousel-viewport {
        //position: relative;
        //width: 100%;
      }
    }
    .nav-block {
      display: flex;
      justify-content: space-between;
      position: relative;
      .nav {
        .btn {
          color: #fff;
          border-color: rgba(255, 255, 255, 0.5);
          border-radius: 2em;
          height: auto;
          padding: calc(0.75em - 1px);
          background-color: transparent;
          box-sizing: border-box;
          font-family: "Icons";
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          text-align: center;
          vertical-align: middle;
          font-size: 1em;
          cursor: pointer;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          &.disabled {
            background-color: transparent;
            color: rgba(255, 255, 255, 0.2);
            border-color: initial;
            cursor: default;
            opacity: 0.2;
            &:hover {
              color: #fff;
              border-color: rgba(255, 255, 255, 0.5);
            }
          }
          &:hover {
            color: #008fdf;
            border-color: #008fdf;
          }
          button:after {
            display: flex;
            width: 1.5em;
            height: 1.5em;
            justify-content: center;
            align-items: center;
            content: "\e202";
          }
          &.nav-prev:after {
            transform: scaleX(-1);
            display: flex;
            justify-content: center;
            align-items: center;
            content: "\e202";
          }
        }
      }
      .nav + .nav {
        margin-left: 0.5em;
      }
      .nav-line-wrap {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        margin-left: 2em;
        .nav-line {
          height: 1px;
          background-color: rgba(255, 255, 255, 0.3);
          width: 50%;
          position: relative;
          overflow: hidden;
          .carousel-line-selected {
            position: absolute;
            left: 0;
            height: 100%;
            background-color: #008fdf;
            transition: transform 0.3s ease-out, opacity 0.3s ease;
          }
        }
      }
      .nav-all {
        .btn.border {
          background-color: transparent;
          border: 1px solid;
          box-sizing: border-box;
          color: #008fdf;
          padding: 1em 1.5em;
          border-radius: 0.25em;
          cursor: pointer;
          display: inline-flex;
          font-size: 1em;
          font-weight: 600;
          line-height: 1em;
          position: relative;
          text-align: center;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          -webkit-tap-highlight-color: transparent;
          vertical-align: middle;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

swiper-slide {
  width: auto !important;
}
</style>
