<script setup>
import { inject, ref, watch, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { Auth } from "@/services/Auth.js";
import { useToast } from "vue-toast-notification";

const props = defineProps({
  tab: { type: String, default: "in" },
});

const router = useRouter();

const $toast = useToast();

const emit = defineEmits(["done", "signup-done"]);

const authService = new Auth(inject("axios"), inject("$cookies"));

const activeTab = ref("in");
const loginEmail = ref("");
const loginPassword = ref("");
const signUpEmail = ref("");
const signUpPassword = ref("");
const passwordConfirm = ref("");
const first_name = ref("");
const last_name = ref("");
const phone = ref("");
const signUpErrors = ref({});
const loginErrors = ref({});
const forgotEmail = ref("");
const formHeight = ref(348);

const onForgotView = () => {
  activeTab.value = "forgot";
};

const onSignUpSubmit = () => {
  signUpErrors.value = {};
  const profile = {
    email: signUpEmail.value,
    password: signUpPassword.value,
    password_confirmation: passwordConfirm.value,
    first_name: first_name.value,
    last_name: last_name.value,
    phone: phone.value,
  };
  authService
    .signup(profile)
    .then(() => {
      emit("signup-done");
      activeTab.value = "in";
      $toast.success("Successfully signed up!");
    })
    .catch(({ response }) => {
      signUpErrors.value = response.data;
    });
};
const onLoginSubmit = () => {
  loginErrors.value = {};
  authService
    .login(loginEmail.value, loginPassword.value)
    .then(() => {
      emit("done");
      $toast.success("Successfully logged in");
      router.push("/");
    })
    .catch(({ response }) => {
      loginErrors.value = response.data;
    });
};

const calcFormHeight = () => {
  const bodyWidth = window.document.body.getBoundingClientRect().width;
  if (activeTab.value === "in") {
    if (bodyWidth > 700) {
      formHeight.value = 270;
    } else if (bodyWidth < 700) {
      formHeight.value = 210;
    }
  }

  if (activeTab.value === "up") {
    if (bodyWidth > 980) {
      formHeight.value = 590;
    } else if (bodyWidth > 700 && bodyWidth < 900) {
      formHeight.value = 500;
    } else if (bodyWidth < 700) {
      formHeight.value = 450;
    }
  }
};

onBeforeMount(() => {
  calcFormHeight();
  activeTab.value = props.tab;
});

watch(activeTab, calcFormHeight);
</script>

<template>
  <div class="popup-auth">
    <div class="auth-wrap">
      <div class="auth-image" :class="{ scaled: activeTab === 'up' }">
        <div class="auth-image-bg">
          <img
            src="@/assets/images/auth/auth-image-bg.svg"
            alt="auth background"
          />
        </div>
        <div class="auth-image-robot" id="auth_image_robot_anim"></div>
        <div class="auth-image-anim">
          <img
            src="@/assets/images/auth/auth-image-bg2.svg"
            alt="auth background"
          />
        </div>
      </div>
      <div class="auth-content">
        <nav class="auth-nav">
          <button
            type="button"
            @click="activeTab = 'in'"
            class="auth-nav-btn in"
            :class="{ active: ['in', 'forgot'].includes(activeTab) }"
            data-layout="signin"
          >
            Sign in
          </button>
          <button
            type="button"
            @click="activeTab = 'up'"
            class="auth-nav-btn up"
            :class="{ active: activeTab === 'up' }"
            data-layout="signup"
          >
            Sign up
          </button>
        </nav>
        <div class="auth-outer" :style="{ height: formHeight + 'px' }">
          <form
            action=""
            class="auth-layout"
            :class="{ active: activeTab === 'forgot' }"
          >
            <input
              type="email"
              required
              v-model="forgotEmail"
              placeholder="Email"
            />
            <button type="submit">Reset Password</button>
          </form>
          <form
            action=""
            class="auth-layout"
            :class="{ active: activeTab === 'in' }"
            @submit.prevent="onLoginSubmit"
          >
            <label
              :class="{ error: Object.keys(loginErrors).includes('email') }"
            >
              <input
                type="email"
                required
                v-model="loginEmail"
                placeholder="Email"
              />
              <span v-if="Object.keys(loginErrors).includes('email')">{{
                loginErrors.email
              }}</span>
            </label>
            <label
              :class="{ error: Object.keys(loginErrors).includes('password') }"
            >
              <input
                type="password"
                required
                v-model="loginPassword"
                placeholder="Password"
              />
              <span v-if="Object.keys(loginErrors).includes('password')">{{
                loginErrors.password
              }}</span>
            </label>
            <div v-if="false" class="auth-forgot-wrap" @click="onForgotView">
              <button
                class="btn-text hl auth-nav-btn"
                type="button"
                id="nav_forgot"
                data-layout="forgot"
              >
                Forgot password
              </button>
            </div>
            <button type="submit">Sign In</button>
          </form>
          <form
            action=""
            class="auth-layout"
            :class="{ active: activeTab === 'up' }"
            @submit.prevent="onSignUpSubmit"
          >
            <label
              :class="{ error: Object.keys(signUpErrors).includes('email') }"
            >
              <input
                type="email"
                required
                v-model="signUpEmail"
                placeholder="Email"
              />
              <span v-if="Object.keys(signUpErrors).includes('email')">{{
                signUpErrors.email
              }}</span>
            </label>
            <label
              :class="{ error: Object.keys(signUpErrors).includes('password') }"
            >
              <input
                type="password"
                required
                v-model="signUpPassword"
                placeholder="Password"
              />
              <span v-if="Object.keys(signUpErrors).includes('password')">{{
                signUpErrors.password
              }}</span>
            </label>
            <label
              :class="{
                error: Object.keys(signUpErrors).includes(
                  'password_confirmation'
                ),
              }"
            >
              <input
                type="password"
                required
                v-model="passwordConfirm"
                placeholder="Password confirmation"
              />
              <span
                v-if="
                  Object.keys(signUpErrors).includes('password_confirmation')
                "
                >{{ signUpErrors.password_confirmation }}</span
              >
            </label>
            <label
              :class="{
                error: Object.keys(signUpErrors).includes('first_name'),
              }"
            >
              <input
                type="text"
                required
                v-model="first_name"
                placeholder="First name"
              />
              <span v-if="Object.keys(signUpErrors).includes('first_name')">{{
                signUpErrors.first_name
              }}</span>
            </label>
            <label
              :class="{
                error: Object.keys(signUpErrors).includes('last_name'),
              }"
            >
              <input
                type="text"
                required
                v-model="last_name"
                placeholder="Last name"
              />
              <span v-if="Object.keys(signUpErrors).includes('phone')">{{
                signUpErrors.last_name
              }}</span>
            </label>
            <label
              :class="{ error: Object.keys(signUpErrors).includes('phone') }"
            >
              <input
                type="text"
                required
                v-model="phone"
                placeholder="Phone number"
              />
              <span v-if="Object.keys(signUpErrors).includes('phone')">{{
                signUpErrors.phone
              }}</span>
            </label>
            <button type="submit">Sign Up</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.popup-auth {
  border-radius: 0.5em;
  margin: auto;
  max-width: 50em;
  overflow: hidden;
  pointer-events: auto;
  user-select: none;

  .auth-wrap {
    display: flex;

    .auth-image {
      position: relative;
      width: 373px;
      background-color: #121428;
      overflow: hidden;
      @media only screen and (max-width: 600px) {
        display: none;
      }

      &.scaled {
        .auth-image-bg,
        .auth-image-robot,
        .auth-image-anim {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) scale(1.5);
        }
      }

      .auth-image-bg,
      .auth-image-robot,
      .auth-image-anim {
        transition: all 0.3s ease;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .auth-content {
      flex: 1 0 auto;
      background-color: #21284b;
      @media only screen and (max-width: 600px) {
        width: 300px;
      }
      /*min-width: 26em; */

      .auth-nav {
        display: flex;
        margin: 0 2em;
        padding-top: 1em;
        position: relative;
        font-size: 1em;

        &:before {
          content: "";
          position: absolute;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.2);
          left: 0;
          width: 100%;
          top: 100%;
          margin-top: -1px;
        }

        button {
          background-color: transparent;
          border: 0;
          color: #008fdf;
          cursor: pointer;
          font-size: 1.5em;
          font-weight: 500;
          padding: 0.6em 0.6em;
          flex-grow: 1;
          position: relative;
          overflow: hidden;

          &:after {
            content: "";
            width: 100%;
            position: absolute;
            height: 2px;
            background-color: #008fdf;
            top: 100%;
            margin-top: -2px;
            left: 0;
            transition: transform 0.3s ease;
            transform: translateX(100%);
          }

          &.up:after {
            transform: translateX(-100%);
          }

          &.active:after {
            transform: translateX(0%);
          }
        }
      }

      .auth-outer {
        height: 348px;
        font-size: 1em;
        overflow: hidden;
        position: relative;
        transition: height 0.3s ease;

        .auth-layout {
          transition: transform 0.3s ease, visibility 0s ease 0.3s;
          box-sizing: border-box;
          padding: 2em;
          position: absolute;
          width: 100%;
          visibility: hidden;

          button {
            background-color: #008fdf;
            color: #fff;
            font-weight: bold;
            font-size: 1em;
            padding: 1em 1.5em;
            border: none;
            outline: none;
            width: 100%;
            border-radius: 0.5em;
            cursor: pointer;
          }

          label {
            position: relative;
            margin-bottom: 2em;
            display: block;

            &.error {
              margin-bottom: 0.7em;

              input {
                border: 1px solid red;
              }

              span {
                font-size: 12px;
              }
            }
          }

          input {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background-color: transparent;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 0.25em;
            color: #fff;
            font-size: 1em;
            padding: 0.8334em 0.6666667em;
            width: 100%;

            &:focus {
              border-color: #008fdf;
            }
          }

          .auth-forgot-wrap {
            margin-bottom: 20px;
            padding: 0.6666667em;

            .btn-text {
              font-weight: normal;
              padding: 0;
              color: #008fdf;
              font-size: 0.85em;
              background-color: transparent;
              border: none;
            }
          }

          &.active {
            display: block;
            visibility: visible;
            transform: translateX(0%) !important;
            transition: transform 0.3s ease, visibility 0s ease 0s;
          }

          &:nth-of-type(1) {
            transform: translateX(-100%);
          }

          &:nth-of-type(2) {
            transform: translateX(-100%);
          }

          &:nth-of-type(3) {
            transform: translateX(100%);
          }
        }
      }
    }
  }
}
</style>
