<script setup></script>

<template>
  <ul class="nav menu hoverhl">
    <li><a href="/about">About</a></li>
    <li><a href="/blog">Blog</a></li>
    <li><a href="/faq">FAQ</a></li>
    <!-- <li><a href="/api">API</a></li> -->
    <li><a href="/support">Support</a></li>
  </ul>
</template>

<style scoped>
.nav.menu {
  display: flex;
  margin-left: 15px;
  height: 3.9em;
  white-space: nowrap;
  list-style: none outside none;

  li {
    float: left;
    height: 100%;

    a {
      color: #fff;
      box-sizing: border-box;
      display: flex;
      font-weight: 600;
      padding: 0 1em;
      position: relative;
      -webkit-transition: color 0.3s ease;
      -o-transition: color 0.3s ease;
      transition: color 0.3s ease;
      will-change: color;
      height: 100%;
      align-items: center;
      &:hover {
        color: #53ae94;
        &:after {
          width: calc(100% - 2em);
          background-color: #53ae94;
        }
      }
      &:after {
        content: "";
        display: block;
        width: 0;
        left: 1em;
        top: 100%;
        position: absolute;
        margin-top: -1px;
        border-top: 2px solid transparent;
        transition: width 0.3s ease, background-color 0.3s ease;
        will-change: width, background-color;
      }
    }
  }
}
</style>
