<script setup>
const props = defineProps({
  backgroundImage: String,
  title: String,
  description: String,
  date: String,
  link: String,
  isActive: Boolean,
});
</script>

<template>
  <a href="" class="blog-post">
    <div class="blog-post-img">
      <img :src="props.backgroundImage" :alt="title" alt="post background" />
    </div>
    <div class="blog-post-body">
      <div class="blog-post-webkitbox">
        <header class="blog-post-head">{{ props.title }}</header>
        <div class="blog-post-desc">
          {{ props.description }}
        </div>
      </div>
      <div class="blog-time">
        <time timestamp="1669896000">{{ props.date }}</time>
      </div>
    </div>
  </a>
</template>

<style scoped>
.blog-post {
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  overflow: hidden;
  position: relative;
  width: auto;
  max-width: 350px;
  height: 466px;
  .blog-post-img {
    max-height: 220px;
    background-color: #21284b;
    position: relative;
    overflow: hidden;
    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: auto;
      max-width: 100%;
      z-index: 2;
    }
  }
  .blog-post-body {
    background-color: #21284b;
    color: #fff;
    padding: 1.8em 1.5em 2em;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    justify-content: space-between;
    .blog-post-webkitbox {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
      margin-bottom: 1.375em;
      .blog-post-head {
        font-size: 1.25em;
        line-height: 1.6em;
        margin-bottom: 0.75em;
      }
      .blog-post-desc {
        line-height: 1.375em;
        opacity: 0.7;
      }
    }
    .blog-time {
      font-size: 0.8125em;
      opacity: 0.7;
    }
  }
}
</style>
