import { createStore } from "vuex";

const store = createStore({
  state: {
    profile: null,
    exchangeConfig: [],
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = payload;
    },
    setExchangeConfig(state, payload) {
      state.exchangeConfig = payload;
    },
  },
  actions: {
    setProfile({ commit }, payload) {
      commit("setProfile", payload);
    },
    setExchangeConfig({ commit }, payload) {
      commit("setExchangeConfig", payload);
    },
  },
});

export default store;
