<script setup></script>

<template>
  <div class="main-background">
    <img
      class="main-bg"
      fetchpriority="high"
      src="../../assets/images/space.svg"
      alt="main background"
    />
    <div class="main-bg-comets">
      <div class="comet"></div>
      <div class="comet"></div>
      <div class="comet"></div>
      <div class="comet"></div>
      <div class="comet"></div>
      <div class="comet"></div>
    </div>
    <img
      class="main-bg"
      src="../../assets/images/main_bg_ground.svg"
      alt="main background"
    />
  </div>
</template>

<style scoped>
@keyframes main_comet {
  0% {
    transform: translate3d(200%, -194%, 0);
    opacity: 0;
  }
  3% {
    transform: translate3d(40%, -36%, 0);
    opacity: 1;
  }
  6% {
    transform: translate3d(-120%, 122%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(-120%, 122%, 0);
    opacity: 0;
  }
}
.main-background {
  background-color: #0c0d16;
  background-image: linear-gradient(0deg, #0c0d16 30%, #151b22 100%);
  height: 120%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  svg,
  img {
    display: block;
    width: 100%;
    left: 50%;
    height: auto;
    bottom: 20%;
    position: absolute;
    transform: translate(-50%);
  }
  .main-bg-comets {
    .comet {
      animation: 40s linear normal none infinite running main_comet;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' width='201' height='171' fill='none'%3E%3Cg filter='url%28%23a%29'%3E%3Ccircle cx='17.989' cy='152.505' r='4.989' fill='%23E89D67'%2F%3E%3C%2Fg%3E%3Ccircle cx='17.989' cy='152.505' r='2.993' fill='%23fff'%2F%3E%3Cpath stroke='url%28%23b%29' stroke-linecap='round' stroke-width='.5' d='M189.368 7 16.991 153.503'%2F%3E%3Cdefs%3E%3ClinearGradient id='b' x1='17.334' x2='181.538' y1='155.208' y2='16.953' gradientUnits='userSpaceOnUse'%3E%3Cstop%2F%3E%3Cstop offset='0' stop-color='%23fff'%2F%3E%3Cstop offset='1' stop-color='%23fff' stop-opacity='0'%2F%3E%3C%2FlinearGradient%3E%3Cfilter id='a' width='35.977' height='35.977' x='0' y='134.516' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0'%2F%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'%2F%3E%3CfeGaussianBlur stdDeviation='6.5'%2F%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0;
      padding-bottom: 8.5%;
      position: absolute;
      width: 10%;
      will-change: transform, opacity;
      &:nth-of-type(1) {
        animation-delay: 2s;
        top: 3%;
        left: 13%;
        width: 12%;
        padding-bottom: 10.2%;
      }
      &:nth-of-type(2) {
        animation-delay: 7s;
        top: 23%;
        left: 72%;
        width: 7%;
        padding-bottom: 6%;
      }
      &:nth-of-type(3) {
        animation-delay: 13s;
        top: 22%;
        left: 33%;
      }
      &:nth-of-type(4) {
        animation-delay: 18s;
        top: 9%;
        left: 48%;
        width: 15%;
        padding-bottom: 12.8%;
      }
      &:nth-of-type(5) {
        animation-delay: 24s;
        top: 2%;
        left: 76%;
      }
      &:nth-of-type(6) {
        animation-delay: 30s;
        top: 29%;
        left: 15%;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .main-background {
    svg,
    img {
      width: auto;
      height: 67em;
      bottom: auto;
      top: 0;
      transform: translate(-50%, 0px);
    }
  }
}
</style>
