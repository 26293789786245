<script setup>
const props = defineProps({
  backgroundImage: String,
  title: String,
  description: String,
  link: String,
  isActive: Boolean,
  item: Object
})

</script>

<template>
<article class="blog-post" :class="{active: isActive}"  :style="{backgroundImage: 'url(' + props.backgroundImage +')'}">
  <div class="blog-post-body">
    <header class="blog-post-head">
      <span class="blog-post-header">{{props.title}}</span>
      <div class="blog-time"><time timestamp="1710590400">March 16, 2024</time></div>
    </header>
    <div class="blog-post-desc">
      {{props.description}}
    </div>
    <div class="blog-post-read-outer">
      <a :href="props.link" class="btn mini">Read</a>
    </div>
  </div>
</article>
</template>

<style scoped>


.blog-post{
  height: 23em;
  margin-right: 10px;
  width: 40em;
  transition: transform .3s ease-out, opacity .3s ease;
  border-radius: 1em;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media only screen and (max-width: 800px) {
    width: 20em;
  }

  .blog-post-body {
    background-color:transparent;
    padding: 1.8em 1.5em 2em;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    width: 54%;

    .blog-post-head {
      color: #fff;
      font-size: 1em;
      font-weight: 500;
      margin-bottom: 1em;
      line-height: 1.6em;
      .blog-post-header {
        cursor: default;
        font-size: 1.5em;
        line-height: 1;
      }
      .blog-time {
        opacity: .7;
        font-size: .6em;
      }
    }
    .blog-post-desc {
      color: #fff;
      cursor: default;
      font-size: .875em;
      line-height: 1.4;
      margin-bottom: 1.7em;
      opacity: 1;
    }
    .blog-post-read-outer {
      a {
        border-radius: .5rem;
        font-size: .875em;
        padding: .75em 1.5em;
        box-sizing: border-box;
        background-color: #008fdf;
        border: 0;
        color: #fff;
        cursor: pointer;
        display: inline-flex;
        font-weight: 600;
        line-height: 1em;
        position: relative;
        text-align: center;
        -webkit-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        -webkit-tap-highlight-color: transparent;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
      }
    }


  }
}

</style>