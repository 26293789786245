<script setup>
import { ref } from "vue";
const storageKey = "cookie_accepted";
const wasAccepted = sessionStorage.getItem(storageKey);

let show = ref(wasAccepted !== "true");

setTimeout(() => (wasAccepted ? null : (show.value = true)), 3_000);
const onAccept = () => {
  show.value = false;
  sessionStorage.setItem(storageKey, "true");
};
</script>

<template>
  <div class="cookie-content" :class="{ active: show }">
    <p>
      We use cookies to provide the best experience on our website. By using the
      CurrencyExchange service you agree to
      <a href="/privacy-policy">Privacy Policy</a> and
      <a href="/terms-of-service">Terms of Service</a>.
    </p>
    <button class="btn submit close" type="button" @click="onAccept">
      Accept &amp; Close
    </button>
  </div>
</template>

<style scoped>
.cookie-content {
  background-color: #20284b;
  border-radius: 0.5em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 1em;
  max-width: 21.7em;
  padding: 1em;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  font-family: "Montserrat", Arial;
  transition: opacity 0.3s ease-in;
  opacity: 0;
  pointer-events: none;
  &.active {
    opacity: 1;
    pointer-events: all;
  }

  p {
    margin-bottom: 1em;
    line-height: 1.5;
  }
  .btn {
    min-width: 10em;
    font-size: 1em;
    padding: 1em 1.5em;

    box-sizing: border-box;
    background-color: #008fdf;
    border-radius: 0.25em;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-weight: 600;
    line-height: 1em;
    position: relative;
    text-align: center;
    transition: all 0.3s ease-in-out;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }
}
</style>
