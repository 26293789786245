<script setup>
import CoinSelectSelf from "@/components/exchange-form/CoinSelectSelf.vue";
import ExchangeEstimate from "./ExchangeEstimate.vue";
import CoinSwapButton from "./CoinSwapButton.vue";
import ExchangeAddressInput from "./ExchangeAddressInput.vue";
import { computed, inject, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Analytics from "@/api/Analytics.js";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import numeral from "numeral";

const store = useStore();

const axios = inject("axios");
const router = useRouter();
const currRoute = useRoute();
const $toast = useToast();
const defaultSendTicker = { ticker: "btc" };
const defaultReceiveTicker = { ticker: "eth" };

const sendCoin = ref(null);
const receiveCoin = ref(null);
const sendAmount = ref(0);
const receivingAmount = ref(0);
const destAddress = ref("");
const exchangeEstimate = ref({
  estimatedAmount: "0",
  transactionSpeedForecast: null,
});
const exchangeError = ref(null);
const isSendCoinLoading = ref(false);
const isReceivingCoinLoading = ref(false);
const addressError = ref(null);
const amountRange = ref({ min: null, max: null });
const isConfigLoading = ref(false);

const exchangeConfig = computed(() => store.state.exchangeConfig);

const shortenedReceivingAmount = computed(() =>
  numeral(receivingAmount.value).format("0.[0000]")
);

const isReceivingCoinLoaderActive = computed(() => {
  return isReceivingCoinLoading.value || receivingAmount.value <= 0;
});

const isSubmitButtonDisabled = computed(() => {
  return (
    isSendCoinLoading.value ||
    isReceivingCoinLoaderActive.value ||
    isConfigLoading.value ||
    !destAddress.value.trim() ||
    exchangeError.value
  );
});

const receivingCoinList = computed(() => {
  if (receiveCoin.value) {
    return getAvailableCoinList(receiveCoin.value.ticker);
  }
  return [];
});

const sendingCoinList = computed(() => {
  if (sendCoin.value) {
    return getAvailableCoinList(sendCoin.value.ticker);
  }
  return [];
});

const isReceivingCoinAvailable = computed(() => {
  return sendCoin.value || !isReceivingCoinLoaderActive.value;
});

const isExchangeEstimateShown = computed(() => {
  if (exchangeEstimate.value) {
    return (
      exchangeEstimate.value.estimatedAmount > 0 &&
      exchangeEstimate.value.transactionSpeedForecast
    );
  }
  return false;
});

const getAvailableCoinList = (ticker) => {
  return exchangeConfig.value.filter((coin) => coin.ticker !== ticker);
};

const getConfig = async (sendingTicker, receivingTicker) => {
  isConfigLoading.value = true;
  await axios
    .get("exchange/config")
    .then(({ data }) => {
      store.dispatch("setExchangeConfig", data);
      const ticker1 = data.find((c) => c.ticker === sendingTicker);
      const ticker2 = data.find((c) => c.ticker === receivingTicker);
      if (ticker1) {
        sendCoin.value = ticker1;
      }
      if (ticker2) {
        receiveCoin.value = ticker2;
      }
      isConfigLoading.value = false;
    })
    .catch(() => $toast.error("Something went wrong!"));
};

const getPairRange = async (sendingCoinValue, receivingCoinValue) => {
  if (sendingCoinValue && receivingCoinValue) {
    const exchange_pair = `${sendingCoinValue.ticker}_${receivingCoinValue.ticker}`;
    await axios
      .get(`/exchange/range`, {
        params: {
          exchange_pair,
        },
      })
      .then(({ data }) => {
        if (data?.error) {
          setExchangeError(data);
        } else {
          amountRange.value = { min: data.minAmount || 1, max: data.maxAmount };
        }
      });
  }
};

const getExchangeEstimation = async () => {
  if (sendCoin.value && receiveCoin.value && sendAmount.value > 0) {
    await axios
      .get("exchange/estimate", {
        params: {
          exchange_pair: `${sendCoin.value.ticker}_${receiveCoin.value.ticker}`,
          amount: sendAmount.value,
        },
      })
      .then(({ data }) => {
        if (data?.error) {
          setExchangeError(data);
        }
        receivingAmount.value = data.estimatedAmount;
        exchangeEstimate.value = data;
      });
  }
};

const setExchangeError = (data) => {
  if (data?.message) {
    exchangeError.value = data.message;
  } else {
    exchangeError.value = data.error;
  }
};

const nullifyExchangeError = () => {
  exchangeError.value = null;
};

const onCoinsSwap = async () => {
  nullifyExchangeError();
  isSendCoinLoading.value = true;
  isReceivingCoinLoading.value = true;
  const tempSendingCoin = sendCoin.value;
  sendAmount.value = shortenedReceivingAmount.value;
  sendCoin.value = receiveCoin.value;
  receiveCoin.value = tempSendingCoin;
  await getPairRange(sendCoin.value, receiveCoin.value);
  await getExchangeEstimation();
  isSendCoinLoading.value = false;
  isReceivingCoinLoading.value = false;
};

const setSendingAmount = async (value) => {
  isReceivingCoinLoading.value = true;
  if (amountRange.value) {
    if (value < amountRange.value.min) {
      sendAmount.value = amountRange.value.min;
    } else if (amountRange.value.max && value > amountRange.value.max) {
      sendAmount.value = amountRange.value.max;
    } else {
      sendAmount.value = value;
    }
  } else {
    sendAmount.value = 1;
  }
  await getExchangeEstimation();
  isReceivingCoinLoading.value = false;
};

const handleSendCoinSelect = async (coin) => {
  sendCoin.value = coin;
  await handleCoinSelect();
};

const handleReceivingCoinSelect = async (coin) => {
  receiveCoin.value = coin;
  await handleCoinSelect();
};

const handleCoinSelect = async () => {
  nullifyExchangeError();
  isSendCoinLoading.value = true;
  isReceivingCoinLoading.value = true;
  await getPairRange(sendCoin.value, receiveCoin.value);
  await getExchangeEstimation();
  isSendCoinLoading.value = false;
  isReceivingCoinLoading.value = false;
};

const onStartExchange = (e) => {
  e.preventDefault();
  const data = {
    amount: sendAmount.value,
    from: sendCoin.value.ticker,
    to: receiveCoin.value.ticker,
    receiving_address: destAddress.value,
  };

  axios
    .post("exchange", data)
    .then(({ data }) => {
      const id = data.id;
      router.push({
        name: "order",
        params: { id: id },
        query: { deposit_address: data.deposit_address },
      });
    })
    .catch(({ response }) => {
      if (response.data?.error === "not_valid_address") {
        addressError.value = "Destination address is invalid";
        return;
      }
      $toast.error("Something went wrong!");
    });
  return false;
};

const setDefaultTickers = () => {
  if (currRoute.query.from) {
    defaultSendTicker.ticker = currRoute.query.from;
  }
  if (currRoute.query.to) {
    defaultReceiveTicker.ticker = currRoute.query.to;
  }
};

onMounted(async () => {
  Analytics.pushEvent("home");
  setDefaultTickers();
  isSendCoinLoading.value = true;
  await getConfig(defaultSendTicker.ticker, defaultReceiveTicker.ticker);
  await getPairRange(sendCoin.value, receiveCoin.value);
  await getExchangeEstimation();
  isSendCoinLoading.value = false;
});

watch(amountRange, ({ min }) => {
  if (min > sendAmount.value) {
    sendAmount.value = min;
  }
});
</script>

<template>
  <form class="exchange-form">
    <div class="exchange-amounts">
      <div class="col">
        <CoinSelectSelf
          :title="'Send'"
          :coins="sendingCoinList"
          :coin="sendCoin"
          :disabled="false"
          :amount="sendAmount"
          :min-amount="amountRange.min"
          :loading="isSendCoinLoading"
          :focusable="true"
          :editable="!exchangeError"
          :amount-usd-in="exchangeEstimate.amount_usd_in"
          @change-coin="handleSendCoinSelect($event)"
          @change-amount="setSendingAmount($event)"
        ></CoinSelectSelf>
        <span v-if="exchangeError" class="error-hint">{{ exchangeError }}</span>
      </div>
      <div class="col-middle">
        <CoinSwapButton @swap="onCoinsSwap" />
      </div>
      <div class="col">
        <CoinSelectSelf
          title="Receive"
          :coins="receivingCoinList"
          :coin="receiveCoin"
          :disabled="!isReceivingCoinAvailable"
          :editable="false"
          :loading="isReceivingCoinLoaderActive"
          :amount="shortenedReceivingAmount"
          :amount-usd-out="exchangeEstimate.amount_usd_out"
          @change-coin="handleReceivingCoinSelect($event)"
        ></CoinSelectSelf>
      </div>
    </div>
    <ExchangeEstimate
      v-if="isExchangeEstimateShown"
      :exchange-estimate="exchangeEstimate"
      :receive-coin="receiveCoin"
    />
    <ExchangeAddressInput
      :value="destAddress"
      :address-error="addressError"
      @input:destAddress="destAddress = $event"
    />
    <div class="exchange-button">
      <button
        id="exchange_submit"
        class="exchange-submit"
        type="button"
        :disabled="isSubmitButtonDisabled"
        @click.prevent="onStartExchange"
      >
        <span>Exchange now</span>
      </button>
    </div>
    <div class="exchange-terms">
      By using the site and creating an exchange, you agree to the
      CurrencyExchange's <a href="/terms-of-service">Terms of Services</a> and
      <a href="/privacy-policy">Privacy Policy</a>
    </div>
  </form>
</template>

<style scoped>
div {
  position: relative;
  margin: 2.2em auto 0;
  width: 100%;
}

.exchange-amounts {
  margin: 0 0 2.2em;
  display: flex;
  justify-content: space-between;
  z-index: 102;
  position: relative;
  width: 100%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  .col {
    box-sizing: border-box;
    margin-top: 0;

    .error-hint {
      font-size: 12px;
      color: red;
      display: block;
      margin-top: 5px;
    }
  }

  .col-middle {
    margin-top: 0;
    max-width: 108px;
    box-sizing: border-box;
    text-align: center;
    z-index: 12;
    padding: 0.3em 1.1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      padding: 1.3em 1.1em 0;
    }
  }
}

.exchange-button {
  text-align: center;
  width: auto;
  margin: 0;
  margin-top: 2.2em;

  .exchange-submit {
    background-color: #fff;
    border-radius: 0.5em;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    overflow: hidden;
    height: 3.575em;
    padding: 0;
    transition: opacity 0.4s ease;
    will-change: opacity;

    &:disabled {
      cursor: unset;
      opacity: 0.6;
    }

    span {
      width: auto;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      background-color: #008fdf;
      color: #fff;
      display: flex;
      font-size: 1em;
      font-weight: 600;
      height: 100%;
      padding: 0.73em 3em;
    }
  }
}

.exchange-terms {
  margin-top: 1em;
  font-size: 0.85em;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;

  a {
    color: #018fdf;
    font-weight: 600;
  }
}
</style>
